<template>
  <div class="loginCard">
    <div class="card">
      <div class="decoration">
        <h5 class="pb-3 w-80 text-center">Lawyer Control Panel</h5>
        <i class="flaticon-home"></i>
        <h4 class="pb-3 w-50 text-center">Login</h4>
        <span class="blob">
          <svg
            viewBox="0 0 500 500"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            width="100%"
            id="blobSvg"
          >
            <path
              id="blob"
              d="M421.5,283Q364,316,333.5,329Q303,342,276.5,360.5Q250,379,222.5,362.5Q195,346,120.5,357Q46,368,83.5,309Q121,250,133,219.5Q145,189,167.5,167.5Q190,146,220,113.5Q250,81,305,70Q360,59,374,114.5Q388,170,433.5,210Q479,250,421.5,283Z"
              fill="#fff"
            ></path>
          </svg>
        </span>
        <span class="blob"
          ><svg
            viewBox="0 0 500 500"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            width="100%"
            id="blobSvg"
          >
            <path
              id="blob"
              d="M394,323.5Q452,397,370.5,383.5Q289,370,244.5,387.5Q200,405,131,395.5Q62,386,46.5,318Q31,250,106.5,225.5Q182,201,199.5,174.5Q217,148,258,123Q299,98,327.5,135.5Q356,173,346,211.5Q336,250,394,323.5Z"
              fill="#fff"
            ></path></svg
        ></span>
      </div>
      <div class="loginForm">
        <form @submit.prevent="login">
          <div class="input-wrapper">
            <label>
              <b>Username</b>
            </label>
            <input type="text" v-model="form.username" placeholder="Email" />
          </div>
          <div class="input-wrapper">
            <label>
              <b>Password</b>
            </label>
            <input
              :type="showPassword ? 'text' : 'password'"
              v-model="form.password"
              placeholder="********"
            />
          </div>
          <!-- Toggle show password -->
          <fd-checkbox
            v-model="showPassword"
            label="Show Password"
          ></fd-checkbox>
          <div class="d-flex justify-content-end mb-1">
            <router-link :to="{ name: 'ForgotPassword' }">
              Forgot Password?
            </router-link>
          </div>
          <button type="submit" class="btn main w-100 mt-3">Login</button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Login_form",
  props: {
    type: String
  },
  data() {
    return {
      form: {
        username: "",
        password: ""
      },
      showPassword: false
    };
  },
  methods: {
    async login() {
      let req = await this.submitLogin();
      if (req != null) {
        await this.$store.dispatch("login/registerFcm", req);
      }
    },
    async submitLogin() {
      try {
        this.$store.commit("setIsLoading", true);
        let request = await this.$store.dispatch("login/login", {
          data: this.form
        });
        this.$store.commit("setIsLoading", false);

        return request;
      } catch (error) {
        this.$store.commit("setIsLoading", false);
        this.$notify({
          group: "alert",
          type: "error",
          title: "Wrong Credential",
          text: "The email or password is incorrect, please try again."
        });

        throw error;
      }
    }
  }
};
</script>

<style lang="scss">
.loginCard {
  max-width: 140vh;
  padding: 0 16px;
  height: 80%;
  width: 100%;
  .card {
    @include flex(row, center, center);
    box-shadow: 0 2px 5px rgba(black, 0.16);
    background-color: white;
    height: 100%;
    width: 100%;
    .decoration {
      @include flex(column, center, center);
      overflow: hidden;
      position: relative;

      background: #79cbca;
      max-width: calc(4 / 12 * 100%);
      flex: calc(4 / 12 * 100%);
      height: 100%;
      h4 {
        color: white;
        line-height: 1.2;
        z-index: 1;
      }
      h5 {
        color: #d2fffe;
        z-index: 1;
      }
      i {
        font-size: 48px;
        color: white;
        z-index: 1;
      }
      .blob {
        position: absolute;
        width: 50em;
        height: 25em;
        right: -15em;
        bottom: -8em;
        opacity: 0.2;
      }
    }
    .loginForm {
      max-width: calc(8 / 12 * 100%);
      flex: calc(8 / 12 * 100%);
      background-color: white;
      form {
        max-width: 532px;
        padding: 0 16px;
        margin: 0 auto;
        .image {
          margin: 0 auto 24px;
          max-width: 150px;
          width: 100%;
        }
        .input-wrapper {
          @include flex(row, flex-start, center);
          margin: 16px 0;
          padding: 0;
          label {
            flex: calc(3 / 12 * 100%);
            max-width: calc(3 / 12 * 100%);
          }
          input {
            width: 100%;
            font-size: 15px;
            padding: 4px 8px;
            border: 1px solid #bbb;
            flex: calc(9 / 12 * 100%);
            max-width: calc(9 / 12 * 100%);
          }
        }
        .btn {
          background-color: $color-main;
          padding: 8px 16px;
          font-size: 16px;
          color: white;
          border: none;
        }
      }
    }
  }

  @media #{$breakpoint-down-md} {
    max-width: 100%;
    height: 100%;
    padding: 0;
    .card {
      flex-direction: column;
      .decoration {
        flex: calc(4 / 12 * 100%);
        max-width: 100%;
        width: 100%;
      }
      .loginForm {
        @include flex(row, center, center);
        flex: calc(8 / 12 * 100%);
        max-width: 100%;
        width: 100%;
        form {
          width: 100%;
          .input-wrapper {
            flex-direction: column;
            align-items: flex-start;
            input {
              flex: 100%;
              max-width: 100%;
            }
          }
        }
      }
    }
  }
}
</style>
